<div class="contact container">
    <div class="row box3">
        <div class="col-md-6">
            <p class="paragraph" translate>contacttip</p>
            <h2 style="font-weight: bold;" translate>contact</h2>
    
            <div style="padding-top: 2rem;">
                <button class="buttonBlue" routerLink="/contact"translate>more</button>
            </div>

        </div>
        <div class="col-md-6">
            <p class="paragraph"translate>newslettertip</p>
            <h2 style="font-weight: bold;" translate>newsletter</h2>
    
            <div style="padding-top: 2rem;">
                <button class="buttonBlue" routerLink="/category/12/1">Newsletter</button>
            </div>
        </div>
       
    </div>
</div>

