<div class="footer container">
  <div class="row border">
    <div class="col-md-6">
      <img class="img-thumbnail" src="/assets/logo.png" style="width: 80px" />
      <nav class="navbar navbar-expand-lg navbar-light">
        <div class="navbar-collapse" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a
                class="nav-link"
                routerLink="/page/{{
                  links.attributes && links.attributes.oroi_xrisis
                }}"
                translate
                >terms <span style="padding-left: 1rem"> |</span></a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                routerLink="/page/{{
                  links.attributes && links.attributes.privacy_policy
                }}"
                translate
                >privacypolicy<span style="padding-left: 1rem"> |</span></a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                routerLink="/page/{{
                  links.attributes && links.attributes.dilosi_prosvasimotitas
                }}"
                translate
                >accesibility</a
              >
            </li>
          </ul>
        </div>
      </nav>

      <a class="link" href="https://www.sga.gr/" target="_blank"
        >Web Design & Development SGA</a
      >
    </div>
    <div
      class="col-md-6"
      style="
        justify-content: flex-start;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding-top: 3rem;
      "
    >
      <nav class="navbar navbar-expand-lg navbar-light align">
        <div class="navbar-collapse" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a
                class="nav-link"
                target="_blank"
                href="https://www.facebook.com/lesvoschamber/?ref=aymt_homepage_panel"
              >
                <i class="fa-brands fa-facebook"></i
              ></a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                target="_blank"
                href="https://www.instagram.com/lesvoschamber/"
              >
                <i class="fa-brands fa-instagram"></i
              ></a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                target="_blank"
                href="https://twitter.com/lesvos_chamber"
              >
                <i class="pi pi-twitter"></i
              ></a>
            </li>
          </ul>
        </div>
      </nav>
      <p class="paragraph">© {{ year }} <span translate>chamber</span></p>
    </div>
  </div>
</div>
