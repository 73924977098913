import { BrowserModule } from '@angular/platform-browser';
import { Injector, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomepageComponent } from './homepage/homepage.component';
import { ContactComponent } from './components/contact/contact.component';
import { FooterComponent } from './components/footer/footer.component';
import { PageComponent } from './page/page.component';
import { CategoryComponent } from './category/category.component';
import { ContactPageComponent } from './contact-page/contact-page.component';
import { FormsModule } from '@angular/forms';
import { RecaptchaModule, RecaptchaFormsModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha'
import { environment } from 'src/environments/environment';
import { NgMarqueeModule } from 'ng-marquee';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PaginatorModule } from 'primeng/paginator';
import {GalleriaModule} from 'primeng/galleria';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ErrorInterceptor } from './error.interceptor';
import { CalendarModule } from 'primeng/calendar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SearchComponent } from './search/search.component';

const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: environment.cookieDomain // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  palette: {
    popup: {
      background: '#0F3C91'
    },
    button: {
      background: '#f1d600'
    }
  },
  theme: 'classic',
  type: 'opt-out',
  content:{
    message: 'Αυτός ο ιστότοπος χρησιμοποιεί cookies για να εξασφαλίσει ότι θα έχετε την καλύτερη δυνατή εμπειρία.',
    
    "dismiss": "Το καταλαβα!",
    "allow": "Αποδοχή cookies",
    "deny": "Άρνηση",
    "link": "Μάθετε περισσότερα",
    "policy": "Πολιτική Cookies",
    "href": "https://ec.europa.eu/info/cookies_el",
  }
};

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomepageComponent,
    ContactComponent,
    FooterComponent,
    PageComponent,
    CategoryComponent,
    ContactPageComponent,
    SearchComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    RecaptchaModule,
    GalleriaModule,
    NgMarqueeModule,
    HttpClientModule,
    PaginatorModule,
    MatSnackBarModule,
    CalendarModule,
    RecaptchaFormsModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }, 
      defaultLanguage: 'el'
    }),
    NgbModule
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.sitekey,
      } as RecaptchaSettings,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
      deps: [Injector],
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
