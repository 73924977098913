import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { environment } from 'src/environments/environment';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DateTime } from "luxon"
import { Router } from '@angular/router';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {

  constructor(private api: ApiService, private translate: TranslateService, private router: Router) { }
  latestNews: any[] = []
  events: any[] = [];
  apiurl = environment.apiUrlPublic
  eventDates: any = [];
  date: Date[] | undefined;
  members: any
  activeBusiness: any = [];
  eggrafesDiagraffes: any = [];
  query: string;
  url = '/page/528'

  async ngOnInit() {
    let language = this.translate.currentLang
    if (this.translate.currentLang == undefined) {
      language = 'el'
    }

    this.latestNews = await this.getLatest(language, '4')

    this.events = await this.getLatest(language, '5')

    var date = new Date();
    var firstDay = new Date().toISOString().slice(0, 8) + '01';
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 1).toISOString().split('T')[0];

    this.eventDates = await this.getEvents(firstDay, lastDay)

 
    this.translate.onLangChange.subscribe(async (event: LangChangeEvent) => {


      this.latestNews = await this.getLatest(event.lang, '4')
      console.log(this.latestNews)

      this.events = await this.getLatest(event.lang, '5')

      let members: any = await this.api.getMembers(event.lang).toPromise()
      this.members = members.data

    })

    let members: any = await this.api.getMembers(language).toPromise()
    this.members = members.data

    this.activeBusiness = await this.api.getActiveBusinesses(new Date(new Date().getFullYear(), 0, 1).toISOString().split('T')[0], new Date().toISOString().split('T')[0]).toPromise()
    console.log('activeBusiness', this.activeBusiness)
    this.eggrafesDiagraffes = await this.api.getEggrafesDiagraffes(new Date(new Date().getFullYear(), 0, 1).toISOString().split('T')[0], new Date().toISOString().split('T')[0]).toPromise()
    console.log('eggrafesDiagraffes', this.eggrafesDiagraffes)

  }

  async getLatest(lang, id) {
    let latestNews: any = await this.api.getCategory2(id, 9, lang, 1).toPromise()
    let data = latestNews.data
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      var date = new Date(element.attributes.published);
      var n = date.toLocaleDateString();
      element.attributes.published = n
      if (element.attributes.cover.data) {
        element.cover = `${this.apiurl}${element.attributes.cover.data.attributes.url}`
      } else {
        element.cover = `/assets/logo_lesvos_new.png`
      }

    }

    return latestNews.data
  }

  async getEvents(firstDay, lastDay) {

    let monthEvents: any = await this.api.getEvents('3', 'el', firstDay, lastDay).toPromise()


    for (let index = 0; index < monthEvents.data.length; index++) {
      const element = monthEvents.data[index];
      element.day = element.attributes.event_date.slice(-2)
      element.year = element.attributes.event_date.slice(0, 4)
      element.month = element.attributes.event_date.slice(5, 7)

    }

    return monthEvents.data
  }


  checkDateForHoliday(date: any) {

    let isEvent = false
    for (let index = 0; index < this.eventDates.length; index++) {
      const element = this.eventDates[index];
      date.day = date.day.toString()
      if (date.day.length == 1) {
        date.day = `0${date.day}`
      }

      // console.log(element.day, `${date.day}`)
      if (element.day == `${date.day}`) {
        // console.log('trueeeeeeeee')
        isEvent = true
      }

    }

    return isEvent
  }

  async onMonthChange(date) {
    console.log('month change', date)


    let dt = new Date(`${date.year}-${date.month}-01`).toISOString()

    const lastDayJan = DateTime.fromISO(dt)
      .endOf('month')
      .toString().slice(0, 10)
    const firstDay = DateTime.fromISO(dt)
      .startOf('month')
      .toString().slice(0, 10)
    console.log(firstDay)
    console.log(lastDayJan)
    this.eventDates = await this.getEvents(firstDay, lastDayJan)
  }

  async onSelect(datee) {


    var date = datee.toISOString()
    let formattedDate = DateTime.fromISO(date.toString());
    console.log(formattedDate.toString().slice(0, 10))
    let d = formattedDate.toString().slice(0, 10)

    let event = await this.getEvents(d, d)
    if (event.length > 0) {
      this.router.navigate([`/page/${event[0].id}`]);
    }


  }

  async search() {
    

    // console.log(keys)
    this.router.navigate(['/search'], {queryParams: {queryText: this.query}});
  }
}
